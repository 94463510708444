import React, { useEffect, useState, useCallback } from "react";
import { Chart } from "react-google-charts";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import student from "../images/total.png";
import enrol from "../images/enrol.png";
import paid from "../images/paid.png";
import regis from "../images/registered.png";

const dataOld = [
  ["Name", "Popularity"],
  ["Cesar", 250],
  ["Rachel", 4200],
  ["Patrick", 2900],
  ["Eric", 8200],
];

const dataNew = [
  ["Name", "Popularity"],
  ["Cesar", 370],
  ["Rachel", 600],
  ["Patrick", 700],
  ["Eric", 1500],
];

export const diffdata = {
  old: dataOld,
  new: dataNew,
};

export const data2 = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
];

export const options2 = {
  title: "My Daily Activities",
  pieHole: 0.4,
  is3D: false,
};

export const data3 = [
  ["Year", "Sales", "Expenses", "Profit"],
  ["2020", 1000, 400, 200],
  ["2021", 1170, 460, 250],
  ["2022", 660, 1120, 300],
  ["2023", 1030, 540, 350],
];

export const options3 = {
  chart: {
    title: "Student Admission In",
  },
};

const Box = ({ onClick, count, topic, imgSrc, imgAlt }) => (
  <div className="box" onClick={onClick}>
    <div className="text">
      <h2 className="topic-heading">{count}</h2>
      <h2 className="topic">{topic}</h2>
    </div>
    <img src={imgSrc} alt={imgAlt} />
  </div>
);

function ErpDashboard() {
  const [todayRegisteredCount, setTodayRegisteredCount] = useState(0);
  const [totalRegisteredCount, setTotalRegisteredCount] = useState(0);
  const [totalPaidStudentCount, setTotalPaidStudentCount] = useState(0);
  const [totalEnrolled, setTotalEnrolled] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_STD_COUNT);
        const data = await response.json();
        setTodayRegisteredCount(data.count);
        setTotalRegisteredCount(data.Totalcount);
        setTotalPaidStudentCount(data.paidStudentCount);
        setTotalEnrolled(data.TotalEnrolled);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: 0,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingTop: 10,
    });

    xRenderer.grid.template.setAll({ location: 1 });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "year",
        tooltip: am5.Tooltip.new(root, { labelText: "{valueY}" }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeWidth: 1,
      strokeOpacity: 1,
      fillOpacity: 0.7,
    });

    series.set("column.width", am5.percent(50));

    series.columns.template.adapters.add("fill", (fill, target) =>
      chart.get("colors").getIndex(series.columns.indexOf(target))
    );

    const data = [
      { year: "2011", value: 2025 },
      { year: "2012", value: 2025 },
      { year: "2013", value: 2025 },
      { year: "2014", value: 2025 },
      { year: "2015", value: 2025 },
      { year: "2016", value: 2025 },
      { year: "2017", value: 1882 },
      { year: "2018", value: 1809 },
      { year: "2019", value: 1322 },
      { year: "2020", value: 1122 },
      { year: "2021", value: 1114 },
      { year: "2022", value: 984 },
      { year: "2023", value: 711 },
    ];

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  const handleClick = useCallback(
    () => navigate("/registered/studentlist"),
    [navigate]
  );
  const handleFee = useCallback(
    () => navigate("/student/totalfeepaid"),
    [navigate]
  );
  const totalRegisteredStudent = useCallback(
    () => navigate("/student/totalregistered"),
    [navigate]
  );
  const TotalEnrolled = useCallback(
    () => navigate("/student/totalenrolled"),
    [navigate]
  );

  return (
    <>
      <AdminDashboard />
      <style>
        {`
        @import url(
          "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: "Poppins", sans-serif;
          }
          :root {

            --primary-color: #4b49ac;
            --secondary-color: #0c007d;
            --Border-color: #3f0097;
            --one-use-color: #3f0097;
            --two-use-color: #5500cb;
          }

          .main-container {
            display: flex;
            width: 100vw;
            position: relative;
            top: 70px;
            z-index: 1;
          }


          .main {
            height: calc(100vh - 70px);
            width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 40px 30px 30px 30px;
          }


          .box-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            gap: 50px;
            margin-left:60px;          
          }

          .box {
            height: 120px;
            width: 250px;
            border-radius: 20px;
            box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751);
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
            transition: transform 0.3s ease-in-out;
          }
          .box:hover {
            transform: scale(1.08);
          }

          .box:nth-child(1) {
            background-color: #1E2A5E;
          }
          .box:nth-child(2) {
            background-color: #1E2A5E;
          }
          .box:nth-child(3) {
            background-color: #1E2A5E;
          }
          .box:nth-child(4) {
            background-color: #1E2A5E;
          }

          .box img {
            height: 45px;
          }
          .box .text {
            color: white;
          }
          .topic {
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 1px;
          }

          .topic-heading {
            font-size: 30px;
            letter-spacing: 3px;
          }

          .report-container {
            min-height: 300px;
            max-width: 1200px;
            margin: 70px auto 0px auto;

            border-radius: 30px;
            box-shadow: 3px 3px 10px rgb(188, 188, 188);
            padding: 0px 20px 20px 20px;
          }


          @media screen and (max-width: 850px) {

            .main {
              padding: 40px 30px 30px 60px;
            }
           }`}
      </style>

      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width,
               initial-scale=1.0"
      />

      <link rel="stylesheet" href="style.css" />
      <link rel="stylesheet" href="responsive.css" />

      <div className="main-container">
        <div className="main">
          <div className="box-container">
            <Box
              onClick={totalRegisteredStudent}
              count={totalRegisteredCount}
              topic="Total Registered"
              imgSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAwtJREFUWEftl1uIzVEUh7+fIZeEmXJJKSNFueYeL+NFQkNJ3ihSXoRi1JhcQmpkBi8axQNvE0V4UJKXSR7kMoU8uEVpBmkmd2Y5a+yZjjPnnP+e6UyNmvX4P3v99rfXWnutfUQfM/UxHvqBkjLSKxEys5FAiyRLAsj8vbeALgEjgPWSPnYHqltAZrYW2AksCZu0AKeB45Ka/ZuZrQPqw+/3gUWSfsRCRQGZ2eCwSXkO4U/AKkkNZlYFHEpbVy1pT6GBaoEdCaLvgWmSmsxsf2rtgbD+JzBZ0usYqMQImdkM4FGMGFAnaWtI3S1gafDzlO6K0YgBOgVsixEDvgLFkr6b2UrgWkctSZoToxEDdANYFiMW1syU1Ghmw4BWYIDXeqquimLaQAyQn9JPG2vzJN0zs6HAl+DUJqkoRiAG6BgQlX/gF1AiqdXMpqb60JMA0SxpTKGAXPgxRM29c5I2h6I+DOwNEFckrSkIUBA/AWxPEPwATE/dsndmNgl4CgwKPt6jrhcSyBvjRW9+eRrjCkl3zGw4cBNYGNb+BrzQPcqJllhDHQpm5mt9LFQAc8N3n1M+Omp8ZpmZzy+HmZ+xc1uqtrx9VEjyRpnTooESj/Z3jg0EyoDVPliB0Rl+t8OI+ZxLLwooPCcWA1OAURlifrNeAY2pwn2QFtEhwBagEhiX5lMvyWGzWl4gM1serryPAG9wSfYWOAPU+tUPF8LfRjXApjTnjZLOZxPLChTqpS6cMAki2+8+SMskvUiL2OWQSv/kT5VSSV1SlwvobMaJegLVFN5C7VBmVhwa5dggVinpaKZwFyAz2w1U94Qgi88zYJakbwHKa8pT6vZSUmleIDMbDzwHvO8UyjojYWZ+IbyBdtTjREl+ITrtnwiZ2b7UiDhYKJKg80bShLRaagD8xrqVS7qaD+gusKDAQC43W9LDkDZvpO2POH+FSjqZD8jDWdILQBskXYjRzUyZz52YfhOjnb6mStKRGKdMoG7/sYvZxOtSUsejP69L1OiI3LQgy/qBksLYH6H/LkJ/AInH/yUAIE/9AAAAAElFTkSuQmCC"
              imgAlt="Views"          
              imgStyle={{ filter: "invert(1)" }} // Make black-filled to white
            />

            <Box
              style={{ color: "white" }}
              onClick={TotalEnrolled}
              count={totalEnrolled}
              topic="Total Enrolled Student"
              imgSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAf1JREFUWEftlj1oFUEUhb+DiFgJNoLGwkKIaCNKhFionYWFkF7UysJCSBMUMREMxEJ7BTtbUbBJp6KNiJWilqJisEkngj/HHdkH7z12dmY3WQiy0+3O3XO/uXN/VmywpQ3GQw+UupE+Qv9vhGyfAg6XJ3wl6XHqtG32kzlk+yRwB9g95uAjcFbSkzaOY9/UAtmeAp4BWyIC34EpSW/XCyoF9Bw4mnD2SNLpzoFs7wI+Zzj6BWyTFKK15hWNkO1p4EWmh0OSXsdsbc8D18r9BUnhuXLVAe0H3mQC7ZP0vmugTcA3YHsCaqWowp2S3ClQELd9HbiaAJqTtFRnsy5XVgJtBpaBExGHD4EZSX+G90uA4VfHgWPli6fASO8azqmcxrgVOA9cAA6UoiHZ7wL3JYUqG1kuiDJz75+ZCqKBfRKoifDAtjMg2yGxQ7VNFtHYC4QrDOsn8AEIlfVO0u/Or8z2GeAmsCMRpa/ARUkPOqsy27eBSw2va77IhYWqb9ZUZbZvAJcbwgzMZyXdqkjydp3a9kEgOgIyIENu7ZH0pSKnmo8O2/eAcxmO60wWJV1pqzFS9rY/ARNtxcrvXko60lZjHOhHzc9Yro9VSan5F9UaB2rUYWOqw5039xSVnbpiBjXVG4yC6P9OSrCT0ZFyWrffA6Wi10eoj1AqAqn9v179ryWzaV3zAAAAAElFTkSuQmCC"
              imgAlt="Likes"
            />
            <Box
              onClick={handleFee}
              count={totalPaidStudentCount}
              topic="Registration & Fee Paid"
              imgSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAgxJREFUWEftlztrFVEUhb+FEF+tIvhPtJAEDPgoJGppJXZKQhDxgagQIUVEsLMW7ERR8VEICkrIP0iXTiIJWBgk8cV29mVyOQ53Jmee3iJTzpyz9zdrr9lnjxiyS0PGw3ADmZkD3gQuAgdaVm8VeANckbSymesfhczsPjDdMkg2/GNJ5/KAnHpfx0ArkvrVyCpkHcP00knqc2wDDapAlwp9Ag4BO4qs0BXQC+AMMA48A3bmQXUB9Bw4K+m3Q5jZZeDe/wLqKRPAHAbeAbvbAvoAfAVOD0jw0u8HMEeA18DetjzkrX4C+AN48mNBorfAKUk/0zJtqUy/O9foQ2OSXCH3xQjwFDiZqjARwIwCr4A9MY22jqm/A8clfQygridf0GwAM5bC5HomC1kHyGOtA0clzWcDm5kr46XL/cTbaowOdWKzfKlalWCaPMt+uKkdqqoyTZg6q/gGcDcd6nbFGLitklXNPXBfXVM3CtOkhxoDK1LoSwfDffZFliUd7Bs8fGpms8C1xl49LtCMpFt5QD7S3gCmgP1x8Sqv+gw8kDQXRoj+UTSzO8kRdrsg/XIyBUxKelIZ0Qf+2M0FQP6n8jA5Uq5KWouNl7euLtAicF7SQl2QgR4qCppRyDv0TDJ4zUn61RRMryfFBguA3gMXJC3F7i2zrgzQJeCbpEdlEpRdGw1UNnDV9dtAWyn3F/AR2CWs77rVAAAAAElFTkSuQmCC"
              
              imgAlt="Comments"
            />
            <Box
              onClick={handleClick}
              count={todayRegisteredCount}
              topic="Today Registered"
              imgSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAk5JREFUWEftlk+oTVEYxX8rSUb+TCgMDBQxET3FiJFioMyFkYEBD/n36Pn3Ho/3DMwoM1NRUiaSmEhGhKGQUpKJlD/L2bdza9/z7j17O4966e7Zvt/a31rfOt+39xXTbGma6aEvKPVF+g79vw7Z3gqsLSt8KulOqtom8WQP2d4MXAWWVAjeADslPWhC3OtMrSDbA8BDYFaPBF+BAUkv/paolKBHwIYE2W1J2/65INuLgHcZRD+AOZKCW1NePR2yvR54nMmwRtKzTGwLZnuTpPvVM3WCVgLPM0lWSHqViQ1iJoD9BX5Y0qn4XJ2gEPsEzEsQfQQWSnKOINvjwGCE3SLpbnufaurTwIkE0RFJFzLFXAQORthxSfG+/rW3PRO4B2zsQXgL2C7pV0qQ7UvAgQg3ISnet0I5F+NsYDewB1hVJgzNfg24ISlMWceyPQTMbVdvewQ4GoHGJB3uVkRSUKryLmLGgEPl76Fhg8vHItyopHjfkSLVQzOAMG3LCzeWlclDgu/AayBM1ktJP9tZbV8B9vYoZETS8boi66ZsBxCqXZBw6UMQIOlmJKo6SSF0pui1kynHuwqyfRnYlzpciXfcKbbjiRqSdC4n3yRBts8Wn6TW1prEg5JCMa1l+3xxAX6RNJojZtKU2V4N/NETUCEKvbVU0vtcAVVch0O2rwO7miYrzyUbN7upbb8FFk9R0BNJ65rmqDr0rebPWC7HZ0nzc8GpT5b1QKbIihe88YVbdWg4RZYTL/Q0ztO4khxhTTB9QSnX+g71HUo5kIr/BkxKriUt4vyAAAAAAElFTkSuQmCC"
              imgAlt="Published"
            />
          </div>
        </div>
      </div>
      <div
        className="shadow p-3 bg-body rounded"
        style={{
          width: "43%",
          height: "400px",
          marginLeft: "130px",
          marginTop: "-22%",
        }}
      >
        <div id="chartdiv" style={{ width: "100%", height: "400px" }}></div>
      </div>
      <div
        className="row2 shadow p-3 bg-body rounded"
        style={{
          margin: "20px",
          width: "100%",
          maxWidth: "500px",
          height: "415px",
          backgroundColor: "#fff",
          marginTop: "-30%",
          marginLeft: "58%",
        }}
      >
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data2}
          options={options2}
        />
      </div>
    </>
  );
}

export default ErpDashboard;
