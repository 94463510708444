import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function PublicSelfDisclosure() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
           margin-left:-20px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          Public Self Disclosure
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-10 padding-section">
            <br />

            <h5
              style={{
                color: "#22177A",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              Public Self-Disclosure{" "}
            </h5>

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 55%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>

            <br></br>

            <table
              style={{
                width: "100%",
                marginLeft: "100px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr></tr>
                <tr>
                  <th>S. No.</th> <th>Title</th> <th>Subtitle</th>
                  <th>Links on Website</th>
                </tr>
                <tr>
                  <th rowSpan="11">A</th>

                  <th rowSpan="4">About HEI</th>

                  <td> Overview:</td>
                  <td>
                    <Link to="/History_Milestones" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td> Act and Statutes or MoA </td>
                  <td>
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/Public_Self_Disclosure/Fianal Statute 12.pdf"
                      target="_blank"
                    >
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Institutional Development Plan </td>
                  <td>
                    <Link to="/InstitutionalDevPlan" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td> Off-campus </td>
                  <td> NA</td>
                </tr>
                <th rowSpan="7">Accreditation/ Ranking Status</th>
                <td> NAAC</td>
                <td>
                  <Link to="/SSR" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr>
                  <td> NBA </td>
                  <td>-----</td>
                </tr>
                <tr>
                  <td>NIRF </td>
                  <td>
                    <Link to="/NIRF" target="_blank"></Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Recognition / Approval (2(f), 12B, etc. as applicable){" "}
                  </td>
                  <td>
                    {" "}
                    <Link to="/approval" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Annual Reports </td>
                  <td>
                    {" "}
                    <Link to="/annual_reports" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Annual Accounts Including Balance Sheet, Income and
                    Expenditure Account, Receipts and Payments Account Along
                    with Audit Report
                  </td>
                  <td>
                    {" "}
                    <Link to="/audit_report" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Sponsoring Body Details, if any</td>
                  <td>
                    {" "}
                    <Link to="/promotingsociety" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>
                  </td>
                </tr>
                <th rowSpan="52">B</th>
                <th rowSpan="52">
                  Administration (Profiles with photographs and contact details)
                </th>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Chancellor
                </th>
                <td>Name: Mr. Siddharth Kapoor</td>
                <tr>
                  <td> Email: info@sssutms.co.in</td>
                </tr>
                <tr>
                  <td> Mobile: 07562-292203</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/chancellor" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <th rowSpan="1" style={{ textAlign: "center" }}>
                  Pro Chancellor
                </th>
                <td> NA</td>
                <tr></tr>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Vice-Chancellor
                </th>
                <td>Name : Dr. Mukesh Tiwari</td>
                <tr>
                  <td> Email: vc@sssutms.co.in</td>
                </tr>
                <tr>
                  <td> Mobile :+91- 9826293590</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/vice_chancellor" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <th rowSpan="1" style={{ textAlign: "center" }}>
                  Pro-Vice Chancellor
                </th>
                <td> NA</td>
                <tr></tr>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Registrar
                </th>
                <td>Name : Dr. Hemant Sharma</td>
                <tr>
                  <td> Email: registrar@sssutms.co.in</td>
                </tr>
                <tr>
                  <td>Mobile:+91-9826512726</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/registrar" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Dy. Registrar
                </th>
                <td>Name : Dr. Kanchan Shrivastav</td>
                <tr>
                  <td> Email: inf@sssutms.co.in</td>
                </tr>
                <tr>
                  <td>Mobile:+91-7477039825</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/deputy_registrar" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <th rowSpan="1" style={{ textAlign: "center" }}>
                  Principal
                </th>
                <td> NA</td>
                <tr></tr>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Finance Officer
                </th>
                <td>Name : Dr. Vimal Nath</td>
                <tr>
                  <td> Email : inf@sssutms.co.in</td>
                </tr>
                <tr>
                  <td>Mobile:07562-292205</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/finance_officer" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Controller of Examination
                </th>
                <td>Name : Dr. Sanjay Rathore</td>
                <tr>
                  <td> Email: exam@sssutms.co.in</td>
                </tr>
                <tr>
                  <td>Mobile:+91-9630560005</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/exam_controller" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Chief Vigilance Officer
                </th>
                <td>Name : Mr H. S. Raghuvanshi </td>
                <tr>
                  <td> Email: info@sssutms.co.in</td>
                </tr>
                <tr>
                  <td>Mobile:+91- 94250 39197</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/Vigilance_officer" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <th rowSpan="4" style={{ textAlign: "center" }}>
                  Ombudsperson
                </th>
                <td>Name : Prof.(Dr.) Varsha Namdeo </td>
                <tr>
                  <td> Email: info@sssutms.co.in</td>
                </tr>
                <tr>
                  <td>Mobile: 07562-292204</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Profile :
                    <Link to="/redressal" target="_blank">
                      <span style={{ color: "blue" }}>Click here</span>
                    </Link>{" "}
                  </td>
                </tr>
                <td rowSpan="1">Board of governors</td>
                <td>
                  <Link to="/governing_body" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Board of Management</td>
                <td>
                  <Link to="/board_of_management" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Academic Council</td>
                <td>
                  <Link to="/academic_council" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Finance Committee</td>
                <td>
                  <Link to="/finance_committee" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Board of Studies</td>
                {/* ///////////////////have to do changes in this page from sssutms.co.in ////////////////////// */}
                <td>
                  <Link target="_blank" to="/board_of_studies">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Internal Complaint Committee</td>
                <td>
                  <Link to="/internal-complaint" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                {/* ///////////////////have to do changes in this page from sssutms.co.in ////////////////////// */}
                <td rowSpan="1">
                  Academic Leadership (Dean/Hod of Schools/Departments/Centres)
                </td>
                <td>
                  <Link to="/head_of_department" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <th rowSpan="15">C</th>
                <th rowSpan="15">Academics</th>
                <td rowSpan="1">Details of Academic Programs</td>
                <td>
                  <Link to="/enggtechno" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Academic Calendar</td>
                <td>
                  <Link to="/academic-calender" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">
                  Statutes/Ordinances pertaining to Academics/Examinations
                </td>
                <td>
                  <Link to="/Hei-handbook" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Schools/Departments/Centres</td>
                <td>
                  <Link to="/institutes" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">List of UGC-recognized ODL/Online programs</td>
                <td>NA</td>
                <tr></tr>
                <td rowSpan="1">Internal Quality Assurance Cell (IQAC)</td>
                <td>
                  <Link to="/iqac_cell" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Library</td>
                <td>
                  <Link to="/Library" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Academic collaborations</td>
                <td>
                  <Link to="/mou-collaboration" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <th rowSpan="5">D</th>
                <th rowSpan="5">Admissions & Fee</th>
                <td rowSpan="1">Prospectus</td>
                <td>
                  <Link to="/Admission/Brochures" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Admission process and guidelines</td>
                <td>
                  <Link to="/Admission/AdmissionProcedure" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Fee refund policy</td>
                <td>
                  <Link
                    to="https://cdn.sssutms.ac.in/media/static/Public_Self_Disclosure/adm_procedure.pdf"
                    target="_blank"
                  >
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <th rowSpan="5">E</th>
                <th rowSpan="5">Research</th>
                <td rowSpan="1">Research and Development Cell</td>
                <td>
                  <Link to="/R&D-Cell" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                {/* ///////////////////incubation cell we have to create//////////////////////////////////////// */}
                <td rowSpan="1">
                  Incubation Centre/Start-Ups/Entrepreneurship Cell
                </td>
                <td>
                  <Link to="/incubation_cell" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                {/* ///////////////////incubation cell we have to create//////////////////////////////////////// */}
                <td rowSpan="1">Central facilities</td>
                <td>
                  <Link to="/Central_facilities" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <th rowSpan="35">F</th>
                <th rowSpan="35">Student Life</th>
                <td rowSpan="1">Sports facilities</td>
                <td>
                  <Link to="/">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">NCC</td>
                <td>
                  <Link to="/activity" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                {/* ///////////////////IN ABOUT NSS PAGE we have to create//////////////////////////////////////// */}
                <td rowSpan="1">NSS</td>
                <td>
                  <Link to="/NSS" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                {/* ///////////////////IN ABOUT NSS PAGE we have to create//////////////////////////////////////// */}
                <tr></tr>
                <td rowSpan="1">Hostel</td>
                <td>
                  <Link to="/Hostel" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <td rowSpan="1">Placement Cell</td>
                <td>
                  <Link to="/Academic/TrainingAndPlacementCell" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <td rowSpan="1">
                  Student Grievance Redressal Committee (SGRC) and Ombudsperson
                </td>
                <td>
                  <Link to="/grievance" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <td rowSpan="1">Health Facilities</td>
                <td>
                  <Link to="/Medicalfacility" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <td rowSpan="1">Internal Complaint Committee</td>
                <td>
                  <Link to="/internal-complaint" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <td rowSpan="1">Anti-Ragging Cell</td>
                <td>
                  <Link to="/anti-ragging" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                {/* ///////////////////IN committe cell  this PAGE we have to create//////////////////////////////////////// */}
                <td rowSpan="1">Equal Opportunity Cell</td>
                <td>
                  <Link to="/equal_opportunity" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                {/* ///////////////////IN committe cell  this PAGE we have to create//////////////////////////////////////// */}
                <tr></tr>
                <tr></tr>
                <td rowSpan="1">
                  Socio-Economically Disadvantaged Groups Cell (SEDG)
                </td>
                <td>
                  <Link to="/equal_opportunity" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <td rowSpan="1">Facilities for differently-abled</td>
                <td>
                  <Link to="/" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <tr></tr>
                <th rowSpan="2">G</th>
                <th rowSpan="2">Alumni</th>
                <td rowSpan="1">Alumni Association</td>
                <td>
                  <Link to="/alumni" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <th rowSpan="17">H</th>
                <th rowSpan="17">Information Corner</th>
                <td rowSpan="1">
                  RTI: Details of Central Public Information Officer (CPIO) and
                  Appellate Authority
                </td>
                <td>
                  <Link to="/" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <td rowSpan="1">Circulars and Notices</td>
                <td>
                  <Link to="/" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                <td rowSpan="1">Announcements</td>
                <td>
                  <Link to="/" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                <td rowSpan="1">News letters</td>
                <td>
                  <Link to="/activity/events" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                <td rowSpan="1">News, Recent events & Achievements</td>
                <td>
                  <Link to="/activity/events" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                <td rowSpan="1">Job openings</td>
                <td>
                  <Link to="/career" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                <td rowSpan="1">Reservation Roster</td>
                <td>
                  <Link to="/career" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                <td rowSpan="1">Study in India</td>
                <td>
                  <Link
                    to="https://www.studyinindia.gov.in/admission/registrations"
                    target="_blank"
                  >
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                <td rowSpan="1">
                  Admission procedure and facilities provided to International
                  Students
                </td>
                <td>
                  <Link
                    to="https://cdn.sssutms.ac.in/media/static/Public_Self_Disclosure/INTERNATIONAL_ADMISSION_05_07122024_0637.pdf"
                    target="_blank"
                  >
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                <tr></tr>
                <th rowSpan="5">I</th>
                <th rowSpan="5">Picture Gallery</th>
                {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                <td rowSpan="1">Infrastructure</td>
                <td>
                  <Link to="/galleries/building" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                <td rowSpan="1">Laboratories</td>
                <td>
                  <Link to="/galleries/laboratory" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                <td rowSpan="1">
                  Videos
                  {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                </td>{" "}
                <td>
                  <Link to="/e_content" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>
                {/* ///////////////////in nav  this PAGE we have to create//////////////////////////////////////// */}
                <tr></tr>
                <th rowSpan="10">J</th>
                <th rowSpan="10">Contact us</th>{" "}
                <td rowSpan="1">
                  Details with Phone Number, Official Email ID and Address,
                  Location map
                </td>
                <td>
                  <Link to="/contact" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
                <td rowSpan="1">Telephone Directory</td>
                <td>
                  <Link to="/contact" target="_blank">
                    <span style={{ color: "blue" }}>Click here</span>
                  </Link>
                </td>{" "}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br></br>

      <Footerpage />
    </>
  );
}

export default PublicSelfDisclosure;
