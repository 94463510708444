import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import posImage from "../../../AdminComponent/12.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});
function AdminDashboard() {
  useEffect(() => {
    let arrow = document.querySelectorAll(".arrow");
    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    }
  }, []);
  return (
    <>
      {/* ===============Navbar================================= */}

      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            sx={{
              width: "100%",
              top: 0,
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#071952",
            }}
          >
            <Toolbar>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link to="/admin/erpdasboard">
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      marginRight: "20px",
                    }}
                    src={posImage}
                  />
                </Link>
                <Typography
                  className="heading"
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    textShadow: "2px 2px 10px cadetblue",
                    marginLeft: "25px",
                  }}
                >
                  Sri Satya Sai University Of Technology And Medical Sciences
                  (SSSUTMS)
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </ThemeProvider>
      <>
        <style>
          {`
      @import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@250&family=Tinos&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tinos', serif;
}
.sidebar {
  margin-Top:60px;
  position: fixed;
  top: 0;
  left: 0;
  height: 150%;
  width: 260px;
  background: #000428;
        background-color:#071952;
  // background: -webkit-linear-gradient(to right, #004e92, #000428);
  // background: linear-gradient(to right, #004e92, #990033);
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 78px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 25px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 0 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover {
  background: #1d1b31;
}
.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .icon-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1D2B53;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}


@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

/* Add some basic styling to the container */
.scrollable-list-container {
  max-height: 500px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrollbar */
  border: 1px solid #ccc; /* Add a border for better visibility */
  padding: 10px; /* Add some padding for better spacing */
}

/* Remove default list styles */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style your list items as needed */
li {
  margin-bottom: 4px;
}

/* Style your links */
a {
  text-decoration: none;
  color: #333;
  font-size:15px
}

/* Add hover effect to links */
a:hover {
  text-decoration: underline;
}

      `}
        </style>

        <link
          href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
          rel="stylesheet"
        />
        <div className="sidebar close">
          <ul
            className="nav-links"
            style={{ marginTop: "25px", borderRadius: "20px" }}
          >
            <Link to="/admin/erpdasboard">
              <li>
                    <img
                      width="25"
                      height="25"
                      style={{
                        filter: "invert(1)",
                        marginLeft: "23px",
                        marginTop: "20px",
                      }}
                      src="https://img.icons8.com/material-sharp/24/dashboard-layout.png"
                      alt="student-center"
                    />
                
            

                <ul className="sub-menu blank">
                  <li>
                    <Link className="link_name" to="/admin/erpdasboard">
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </li>
            </Link>
            <li>
              <Link to ="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/windows/32/data-center--v3.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                <li>
                  <Link className="link_name" >
                    Student
                  </Link>
                </li>

                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/enrollment">
                      <li>
                        <h6>Enrollment Generation</h6>
                      </li>
                    </Link>
                    <Link to="/manual/enrollment/generation">
                      <li>
                        <h6>Manul Enrollment Generation</h6>
                      </li>
                    </Link>

                    <Link to="/enrolled/student/search-result">
                      <li>
                        <h6>Enrolled Student</h6>
                      </li>
                    </Link>

                    <Link to="/admin/erpdashboard/admissionslip/search">
                      <li>
                        <h6>Admission Slip</h6>
                      </li>
                    </Link>
                  </ul>
                </div>
              </ul>
            </li>

            <li>
              <Link to ="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/hatch/64/administrator-male.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                <li>
                  <Link className="link_name">
                    Admin/ Admission
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erpdashboard/student/search">
                      <li>
                        <h6>Update Student</h6>
                      </li>
                    </Link>

                    <Link to="/erpdashboard/student/document/search">
                      <li>
                        <h6>Upload Documents</h6>
                      </li>
                    </Link>

                    <Link to="/erpdashboard/student/updateclg&branch">
                      <li>
                        <h6>Update Course & College</h6>
                      </li>
                    </Link>
                    <Link>
                      <li>
                        <h6>Update Fee</h6>
                      </li>
                    </Link>

                    <Link to="/erpdahboard/student/addcourse">
                      <li>
                        <h6>Add Course</h6>
                      </li>
                    </Link>

                    <Link to="/erpdashboard/student/updt/delete/course">
                      <li>
                        <h6>Update & Delete Course</h6>
                      </li>
                    </Link>
                  </ul>
                </div>
              </ul>
            </li>
            {/*-----------------Admision/------------------------------------------------*/}
            {/* <li>
              <div className="icon-link">
                <Link to ="#">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAAAXNSR0IArs4c6QAAAbFJREFUWEftl91RAzEMhKVOSCVAJSSVAJUQKiFUknSysBn75u5iW1JimDxYMzf3EJ38WT9rR+VOTe+USwZYtDIjYyNj0QxE/d09BuBNRB5F5ElETr/vg4h8qirf3c0EA/AgIh8JqATwrqqE7moesK8GVIbpDtcEA7BN2bKycVLVzdwpfftifbj6nRs8t4YFxhISzmPP835LPfnq+XDmM8WwwDxlLJbzr8EiGdup6j5TAuD08onYXlU58WYpvT3GWJscNEJS87VKSalgOfluGXe66wGUY3jkguUgXM0uJrIwnV7mg6uUs34piSx7gcrfFFcAR0fG81K+qVxvM50CEumlfwWb+iBNUKtOADw9mkNwsl0CyxJyMvPhvWZgOfl8WyX1NpnZ/FcIJAGnHUdB1v7FqUzpj4ojY3eDuwBLit2SBysZhON0nRWclmLyFLGs3mPBKaottBDcwC2lLBeBANbOF6IbiFsFo1hGryo1yGmRpH/WsUZ9nK7pix4DELlNWFlb3DYs5+ZU3jCNpXVvum6vM0YxNVPu3D0P5Kv/QZm3CydEd7cBFk3pyNjIWDQDUf8fUGa0JwL3jjoAAAAASUVORK5CYII="
                    style={{
                      width: "27px",
                      marginLeft: "25px",
                      marginTop: "20px",
                    }}
                  />
                </Link>
                <i className="bx bxs-chevron-down arrow" />
              </div>
              <ul className="sub-menu" style={{ marginTop: "-100px" }}>
                <li>
                  <a className="link_name" href="#">
                    Admission
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to ="#">Scholarship</Link>
                    </li>
                    <li>
                      <Link to ="#">Download</Link>
                    </li>
                    <li>
                      <Link to ="#">New Ed. 12th Passing Subject</Link>
                    </li>
                    <li>
                      <Link to ="#">New Ed. Subject mapping Master</Link>
                    </li>
                    <li>
                      <Link to ="#">New Ed. pol. Subject Master</Link>
                    </li>
                    <li>
                      <Link to ="#">New Ed. Subject With Branch Mapping</Link>
                    </li>
                    <li>
                      <Link to ="#">
                        New Ed. Pol. Subject Mapping With Subject Master{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to ="#">
                        New Ed. Pol. Existing Students Subject Update
                      </Link>
                    </li>
                    <li>
                      <Link to ="#">Manual Enrollment Generate</Link>
                    </li>
                    <li>
                      <Link to ="#">Course Group Wise Faculty Assign</Link>
                    </li>
                    <li>
                      <Link to ="#">Course Assign To College</Link>
                    </li>
                    <li>
                      <Link to ="#">Seat Allocation To College</Link>
                    </li>
                    <li>
                      <Link to ="#">Fee Structure With Eligbillity</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Document Upload </Link>
                    </li>
                    <li>
                      <Link to ="#">Cancel Uploaded Document(Student)</Link>
                    </li>
                    <li>
                      <Link to ="#">Counselling And Collage Allotment</Link>
                    </li>
                    <li>
                      <Link to ="#">Counselling WithCollage Selection</Link>
                    </li>
                    <li>
                      <Link to ="#">Collage Change</Link>
                    </li>
                    <li>
                      <Link to ="#">Collage Allotted List</Link>
                    </li>
                    <li>
                      <Link to ="#">Collage Allotted Studennt List</Link>
                    </li>
                    <li>
                      <Link to ="#">Generate Enrollment</Link>
                    </li>
                    <li>
                      <Link to ="#">Enrollment Slip</Link>
                    </li>
                    <li>
                      <Link to ="#">Change Course</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Branch Change After Enrollment</Link>
                    </li>
                    <li>
                      <Link to ="#">Enrollment Cancel</Link>
                    </li>
                    <li>
                      <Link to ="#">Admission Cancel</Link>
                    </li>
                    <li>
                      <Link to ="#">Export Data (Student)</Link>
                    </li>
                    <li>
                      <Link to ="#">Admission Session Change</Link>
                    </li>
                    <li>
                      <Link to ="#">Update Student Details</Link>
                    </li>
                    <li>
                      <Link to ="#">Upload Student Data (Excel)</Link>
                    </li>
                    <li>
                      <Link to ="#">Update Student</Link>
                    </li>
                    <li>
                      <Link to ="#">Admission Form Status</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Document Upload</Link>
                    </li>
                    <li>
                      <Link to ="#">Cancle Uploaded Document(Student)</Link>
                    </li>
                    <li>
                      <Link to ="#">Counselling And Collage Allotment</Link>
                    </li>
                    <li>
                      <Link to ="#">Counselling With Collage Selection</Link>
                    </li>
                    <li>
                      <Link to ="#">Collage Change</Link>
                    </li>
                    <li>
                      <Link to ="#">Collage Allotted List</Link>
                    </li>
                    <li>
                      <Link to ="#">Counselling Admitted Student list</Link>
                    </li>
                    <li>
                      <Link to ="#">Generate Enrollment</Link>
                    </li>
                    <li>
                      <Link to ="#">Enrollment Slip</Link>
                    </li>
                    <li>
                      <Link to ="#">Change Course</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Branch Change After Enrollment</Link>
                    </li>
                    <li>
                      <Link to ="#">Enrollment Cancle </Link>
                    </li>
                    <li>
                      <Link to ="#">Admission Cancle</Link>
                    </li>
                    <li>
                      <Link to ="#">Export Data (Student)</Link>
                    </li>
                    <li>
                      <Link to ="#">Admission Session Change</Link>
                    </li>
                    <li>
                      <Link to ="#">Update Student Details </Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </li> */}

            {/*--------------------------Master--------------------------------*/}
            <li>
              <Link to ="#">
                <img
                  width="30"
                  height="30"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/badges/48/education.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "45px" }}>
                <li>
                  <Link className="link_name" >
                    Masters
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erp/Masters/branchmapping">
                      <li>
                        <h6>Branch Mapping With Session</h6>
                      </li>
                    </Link>
                    <Link to="/erp/Masters/examsessionmaster">
                      <li>
                        <h6>Exam Session Master</h6>
                      </li>
                    </Link>
                    <Link to="/erp/Examination/examinationformcreation">
                      <li>
                        <h6>Examination Form Creation</h6>
                      </li>
                    </Link>

                    <Link to="/center-master">
                      <li>
                        <h6>Center Master</h6>
                      </li>
                    </Link>

                    <Link to="/specialization-master">
                      <li>
                        <h6>Specialization Master</h6>
                      </li>
                    </Link>

                    <Link to="/specialization-subject-mapping">
                      <li>
                        <h6>Specialization Subject Mapping</h6>
                      </li>
                    </Link>
                    {/* <Link to="/sprcialization-type-master">
                      <li>
                        <h6>Specialization Type Master</h6>
                      </li>
                    </Link>
                    <Link to="/subject-header-insert">
                      <li>
                        <h6>Subject Header Insert</h6>
                      </li>
                    </Link>
                    <Link to="/upload-subject-master-from-excel-data">
                      <li>
                        <h6>Upload Subject Master From Excel Data</h6>
                      </li>
                    </Link>
                    <Link to="/create-collage">
                      <li>
                        <h6>Create College</h6>
                      </li>
                    </Link>
                    <Link to="/admission-session-update">
                      <li>
                        <h6>Admission Session Master</h6>
                      </li>
                    </Link>
                    <Link to="/faculty-master">
                      <li>
                        <h6>Faculty Master</h6>
                      </li>
                    </Link>
                    <Link to="/department-master">
                      <li>
                        <h6>Department Master</h6>
                      </li>
                    </Link>
                    <Link to="/course-type">
                      <li>
                        <h6>Course Type</h6>
                      </li>
                    </Link>
                    <Link to="/course-master">
                      <li>
                        <h6>Course Master</h6>
                      </li>
                    </Link>
                    <Link to="/branch-master">
                      <li>
                        <h6>Branch Master</h6>
                      </li>
                    </Link>
                    <Link to="/sub-branch-master">
                      <li>
                        <h6>Sub Branch Master</h6>
                      </li>
                    </Link>
                    <Link to="/course-group-master">
                      <li>
                        <h6>Course Group Master</h6>
                      </li>
                    </Link>
                    <Link to="/admission-open">
                      <li>
                        <h6>Admission Open</h6>
                      </li>
                    </Link>
                    <Link to="/reservation-master">
                      <li>
                        <h6>Reservation Master</h6>
                      </li>
                    </Link>
                    <Link to="/create-counselling-round">
                      <li>
                        <h6>Create Counselling Round</h6>
                      </li>
                    </Link>
                    <Link to="/last-exam-sunject-group">
                      <li>
                        <h6>Last Exam Subject Group</h6>
                      </li>
                    </Link>
                    <Link to="/grade-point-master">
                      <li>
                        <h6>Grade Point Master</h6>
                      </li>
                    </Link>
                    <Link to="/subject-master-excel-download">
                      <li>
                        <h6>Subject Master Excel Download</h6>
                      </li>
                    </Link>
                    <Link to="/subject-master-paper-wise">
                      <li>
                        <h6>Subject Master Paper Wise</h6>
                      </li>
                    </Link>
                    <Link to="/subject-group-master">
                      <li>
                        <h6>Sub Group Master</h6>
                      </li>
                    </Link>
                    <Link to="/transfer-subject">
                      <li>
                        <h6>Transfer Subject</h6>
                      </li>
                    </Link>
                    <Link to="/transfer-subject-group">
                      <li>
                        <h6>Transfer Subject Group</h6>
                      </li>
                    </Link>
                    <Link to="/program-scheduling">
                      <li>
                        <h6>Program Scheduling</h6>
                      </li>
                    </Link> */}
                  </ul>
                </div>
              </ul>
            </li>

            <li>
              <Link to ="#">
                <img
                  width="30"
                  height="30"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/ios/50/search-contacts.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                <li>
                  <Link className="link_name">
                    Search Student
                  </Link>
                </li>

                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erpdashboard/pending/student/search">
                      <li>
                        <h6>Pending Student List</h6>
                      </li>
                    </Link>
                    <Link to="/erpdashboard/verified/student/search">
                      <li>
                        <h6>Verified Student List</h6>
                      </li>
                    </Link>
                  </ul>
                </div>
              </ul>
            </li>

            <li>
              <div className="icon-link">
                <Link to ="#">
                  <img
                    width="25"
                    height="25"
                    style={{
                      filter: "invert(1)",
                      marginLeft: "23px",
                      marginTop: "20px",
                    }}
                    src="https://img.icons8.com/ios/50/video-id-verification.png"
                    alt="student-center"
                  />
                </Link>
                <i className="bx bxs-chevron-down arrow" />
              </div>
              <ul className="sub-menu" style={{ marginTop: "-170px" }}>
                <li>
                  <Link className="link_name" >
                    Verification
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/student/status">
                      <li>
                        <a>Student Status</a>
                      </li>
                    </Link>
                    <li>
                      <Link to ="#">Student Registration Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Document Verification Panel</Link>
                    </li>
                    <li>
                      <Link to ="#">Document Verification</Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </li>
            <li>
              <div className="icon-link">
                <Link to ="#">
                  <img
                    width="25"
                    height="25"
                    style={{
                      filter: "invert(1)",
                      marginLeft: "23px",
                      marginTop: "20px",
                    }}
                    src="https://img.icons8.com/pulsar-line/48/education-fees-payment.png"
                    alt="student-center"
                  />
                </Link>
                <i className="bx bxs-chevron-down arrow" />
              </div>
              <ul className="sub-menu" style={{ marginTop: "-220px" }}>
                <li>
                  <Link className="link_name" >
                    Accounts
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    {/* <li>
                      <Link to ="#">Update Manual Tuition Fees</Link>
                    </li> */}
                    <Link to="/entrance/slip">
                      <li>
                        <Link to ="#">Entrance Exam Details</Link>
                      </li>
                    </Link>
                    <li>
                      <Link to ="#">Exam Fee Master </Link>
                    </li>

                    <Link to="/manually/search/std">
                      <li>
                        <a>Manual Fee Update</a>
                      </li>
                    </Link>
                    <Link to="/entrance/search/std">
                      <li>
                        <a>Entrance Fee Update</a>
                      </li>
                    </Link>

                    <li>
                      <Link to ="#"> Update Manual Enrollment Fee</Link>
                    </li>
                    <Link to="/paym">
                      <li>
                        <Link to ="#">Verify Online Payment</Link>
                      </li>
                    </Link>
                    {/* <li>
                      <Link to ="#">Examination From Activation</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam From Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Enrollment Fee Vrification Status</Link>
                    </li>
                    <li>
                      <Link to ="#">Conditional Link Clear</Link>
                    </li>
                    <li>
                      <Link to ="#">Data Wise fee Summary</Link>
                    </li>
                    <li>
                      <Link to ="#">Tuition Fee Verification</Link>
                    </li> */}
                  </ul>
                </div>
              </ul>
            </li>
            <li>
              <Link to ="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/pulsar-line/48/test.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "-15px" }}>
                <li>
                  <Link className="link_name" >
                    Examination
                  </Link>
                </li>
                {/* <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to ="#">Examination Form Creation Between Sem</Link>
                    </li>
                    <li>
                      <Link to ="#">Increase Answer Booklet Time</Link>
                    </li>
                    <li>
                      <Link to ="#">Course Wise Center Allocation</Link>
                    </li>
                    <li>
                      <Link to ="#">Bulk Mailing</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Wise Print Admit Card</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Session Master</Link>
                    </li>
                    <li>
                      <Link to ="#">Class Promotion</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Form Status</Link>
                    </li>
                    <li>
                      <Link to ="#">Manual Exam Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Examination Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Question Paper Upload</Link>
                    </li>
                    <li>
                      <Link to ="#">Data Wise Student Strength(Exam)</Link>
                    </li>
                    <li>
                      <Link to ="#">Foil Counter Foil</Link>
                    </li>
                    <li>
                      <Link to ="#">Attendance Sheet (Raba)</Link>
                    </li>
                    <li>
                      <Link to ="#">Increase Answer Booklet Time</Link>
                    </li>
                    <li>
                      <Link to ="#">Course Wise Center Allocation</Link>
                    </li>
                    <li>
                      <Link to ="#">Bulk Mailing</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Wise Print Admit Card</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Session Master</Link>
                    </li>
                    <li>
                      <Link to ="#">Class Promotion</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Form Status</Link>
                    </li>
                    <li>
                      <Link to ="#">Manual Exam Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Examination Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Question For Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Question Paper </Link>
                    </li>
                    <li>
                      <Link to ="#">Data Wise Student Strength (Exam)</Link>
                    </li>
                    <li>
                      <Link to ="#">Foil Counter Foil</Link>
                    </li>
                    <li>
                      <Link to ="#">Attendance Sheet (Raba)</Link>
                    </li>
                    <li>
                      <Link to ="#">Activate Admit Card</Link>
                    </li>
                    <li>
                      <Link to ="#">Examination Fee Discount</Link>
                    </li>
                    <li>
                      <Link to ="#">Online Exam Paper Status</Link>
                    </li>
                    <li>
                      <Link to ="#">lkjhgfdsa</Link>
                    </li>
                  </ul>
                </div> */}
              </ul>
            </li>
            <li>
              <Link to ="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/ios-glyphs/30/student-female.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "-50px" }}>
                <li>
                  <Link className="link_name" >
                    Student
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to ="/erpdashboard/student/Pass-search">
                        Password View
                      </Link>
                    </li>

                    <Link to="/admin/erpdashboard/Bonafied/certificate">
                      <li>
                        <h6>Bonafide Cretificate </h6>
                      </li>
                    </Link>
                    <Link to="/student/history">
                      <li>
                        <h6>Student History</h6>
                      </li>
                    </Link>
                    {/* <li>
                      <Link to ="#">Export Student Data College Wise</Link>
                    </li> */}
                    {/* <li>
                      <Link to ="#">Registration Add Allotment Slip</Link>
                    </li> */}
                  </ul>
                </div>
              </ul>
            </li>

       
            <li>
            
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/hatch/64/administrator-male.png"
                  alt="student-center"
                />
            
              <Link to="/adminlogin">
                <ul className="sub-menu blank" style={{ marginTop: "-6px" }}>
                  <li>
                    <Link className="link_name">Logout</Link>
                  </li>
                </ul>
              </Link>
            </li>

            {/* <li>
              <Link to ="#">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAAAXNSR0IArs4c6QAAAalJREFUWEftl9FRwzAQRG8rgVQC6YRUAlRCqISkEtLJwXokjxGW7s7WePJh/fjDcvS02ltdIHc6cKdcsoNFT2ZXbFcsqkB0vttjqvoiIo8i8pQWuYnIOwA+uw8TTFUJ8yEizzOrE+oTwFtvMg/YVwVqynICcO4J1wRLx0e1rHEDcLAmRd5bYISitzzjCODSmphsMUyxvNkTjIXQ9JqqTm3RnG+BefyVRTJ91hOMCrx6zvG3Og/W8fQEY1RQNT5b4wLgaG2gGxgXclTmbEUmo5fZR/XzJhkv12Iz3OAQ2GaOJbhayJ4BnOaUUtWIDfJPjJXtAstfJRWGHTuiYXOwUaCW2ZMFysKZenXujmVlD1loxQV/iAHLi7t2V3KBq5VhyRLrc2yBRwg47rjiu3VgRVlbKTB934RbFReqyiPjzpYOwrG6/nloLdi3I1At6NkYSfZ4SB+zj6te+n/M7whTCyi/X90GlWBLsqcGa7ZBrV2WYJH+y1LP7DYiYJE2xwIz+7MIWP4nZC3qec8LudnRusE8q201J3SJbwVl3pVbgpRr7YpF1d8Viyr2AwtJwCdxlh6CAAAAAElFTkSuQmCC"
                  style={{
                    width: "33px",
                    marginLeft: "25px",
                    marginTop: "20px",
                  }}
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "-100px" }}>
                <li>
                  <a className="link_name" href="#">
                    User Role
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to ="#">Assign Collage To User</Link>
                    </li>
                    <li>
                      <Link to ="#">Collage Assign To Dean</Link>
                    </li>
                    <li>
                      <Link to ="#">Create User </Link>
                    </li>
                    <li>
                      <Link to ="#">Marks Entry Permission</Link>
                    </li>
                    <li>
                      <Link to ="#">Branch Wise Permission</Link>
                    </li>
                    <li>
                      <Link to ="#">Special Marks Entry Permission</Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </li> */}

            <li></li>
          </ul>
        </div>
      </>
    </>
  );
}

export default AdminDashboard;
